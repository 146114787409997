import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getUser } from '../../store/modules';
import { LoadingSpinner } from '../../containers'
import { getUrlParam } from '../../utils/params';
import { getAccessTokenFromLocalStorage } from '../../utils/getAccessToken';
import { localizedStrings } from '../../constants/localizedStrings';
export default function Authentication({ history }) {

    const dispatch = useDispatch();

    const getUserData = async () => {
        const token = getUrlParam("token")

        const lastToken = getAccessTokenFromLocalStorage();
        await dispatch(getUser({
            urlToken: token,
            lastToken,
        }));
    }
    useEffect(() => {
        getUserData()
    })


    return (
        <>
            <LoadingSpinner
                height={"100vh"}
                alignItems={"center"}
                limit={7000}
                title={localizedStrings.takingTooLong}
                description={localizedStrings.clickHereToReturnToSystem}
            />
        </>
    );
}
        /* <Container
//     containerOptions={{ height: "100%" }}
//     className="col-md-12 col-sm-12 col-12" maxWidth={"650px"}
//     padding={width < 860 ? "32px 12px 32px 12px" : "32px 0 32px 0"}>
// </Container> */
